<template>
  <div class="header-wrap">
    <div class="logo-wrap">
      <!-- <img src="../../assets/imgs/logo.png" /> -->
    </div>
    <div class="menu-wrap">
      <div
        class="menu-item"
        :class="{ 'menu-active': item.value === activeName }"
        v-for="item in menuList"
        :key="item.value"
        @click="clickHandle(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { meetingTracking, thinkTankTracking } from '@/api/tracking/'
// import { Message } from 'view-design'
export default {
  computed: {
    ...mapState('header', ['activeName', 'menuList'])
  },
  watch: {
    $route: {
      handler(val) {
        const temp = val.meta.title || val.name
        this.$store.commit('header/setActiveName', temp)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    clickHandle(item) {
      const { url } = item
      // const temp = ['/literature', '/system', '/personal', '/library']
      // if (!temp.includes(url)) {
      //   Message.warning({
      //     content: '暂未开放,敬请期待！',
      //     duration: 2
      //   })
      //   return
      // }
      if (item.name === '会议速递') {
        meetingTracking({
          data_type: '模块访问'
        })
      }
      if (item.name === '智库') {
        thinkTankTracking({
          data_type: '模块访问'
        })
      }
      this.$store.commit('header/setActiveName', item.value)
      if (url) {
        const { path } = this.$route
        if (path === url) return
        this.$router.push(url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
