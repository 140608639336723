import { BaseServe } from '@/server'

// 会议模块埋点
export const meetingTracking = (data) => {
  return BaseServe({
    url: '/meeting/behavior/',
    method: 'post',
    data
  })
}
// 智库模块埋点
export const thinkTankTracking = (data) => {
  return BaseServe({
    url: '/mind_lib/behavior/',
    method: 'post',
    data
  })
}
// 获取会议模块访问趋势
export const meetingTrend = (params) => {
  return BaseServe({
    url: '/meeting/behavior/dashboard/trend/',
    method: 'get',
    params
  })
}
// 获取会议模块生物标志物关注度
export const meetingBiomarker = (params) => {
  return BaseServe({
    url: '/meeting/behavior/dashboard/biomarker/',
    method: 'get',
    params
  })
}
// 获取会议模块会议关注度
export const meetingAttention = (params) => {
  return BaseServe({
    url: '/meeting/behavior/dashboard/meeting/',
    method: 'get',
    params
  })
}
// 获取会议模块主题下载
export const meetingDownload = (params) => {
  return BaseServe({
    url: '/meeting/behavior/dashboard/topic_download/',
    method: 'get',
    params
  })
}
// 获取会议模块主题转载
export const meetingReprint = (params) => {
  return BaseServe({
    url: '/meeting/behavior/dashboard/topic_share/',
    method: 'get',
    params
  })
}
// 获取智库访问趋势
export const thinkTankTrend = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/trend/',
    method: 'get',
    params
  })
}
// 智库-一级标签点击分布
export const thinkTankFirst = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/first_label/',
    method: 'get',
    params
  })
}
// 智库-二级标签点击分布
export const thinkTankSecond = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/second_label/',
    method: 'get',
    params
  })
}
// 智库-二级标签点击排行
export const thinkTankSecondRank = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/second_label_rank/',
    method: 'get',
    params
  })
}
// 智库-生物标志物关注排行
export const thinkTankBiomarker = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/biomarker/',
    method: 'get',
    params
  })
}
// 智库-生物标志物下拉框
export const thinkTankBiomarkerSelect = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/biomarker_list/',
    method: 'get',
    params
  })
}
// 智库-一级标签下拉
export const thinkTankFirstSelect = (params) => {
  return BaseServe({
    url: '/mind_lib/behavior/dashboard/label_list/',
    method: 'get',
    params
  })
}