<template>
  <div class="layout" id="layout" ref="layout">
    <Layout>
      <div class="header">
        <Header />
      </div>
      <Content>
        <div class="content-wrap">
          <keep-alive :include="includes">
            <router-view />
          </keep-alive>
          <!-- <router-view /> -->
        </div>
      </Content>
      <div class="footer-wrap">
        <div class="footer"></div>
        <div class="footer-text">如需任何使用帮助，请联系邮箱：shanghai.msa_medical_info@roche.com</div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Header from 'components/header'
import { Layout, Content } from 'view-design'
export default {
  components: {
    Header,
    Layout,
    Content
  },
  data() {
    return {
      includes: ['Lists', 'ZhiKuList']
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.$refs.layout.scrollTop = 0
        if (route.name === 'Zhiku') {
          this.includes = ['Lists']
        } else {
          this.includes = ['Lists', 'ZhiKuList']
        }
      }
    }
  }
}
</script>
<style scoped>
.layout {
  height: 100vh;
  background: #f1f5f8;
  position: relative;
  overflow-x: hidden;
}
/* .layout::-webkit-scrollbar {
  display: none;
} */
.header {
  width: 100%;
  position: absolute;
}
.footer-wrap {
  padding: 0 72px 40px 72px;
}
.footer-text {
  margin-top: 16px;
  font-size: 14px;
  color: #8b9cab;
  text-align: center;
}
.footer {
  width: 100%;
  height: 64px;
  border-radius: 12px;
  background: url('../../assets/imgs/footer.png') no-repeat center;
  background-size: cover;
}
.content-wrap {
  margin-top: 88px;
  padding: 0 72px;
}
@media screen and (max-width: 1366px) {
  .content-wrap {
    padding: 0 24px;
  }
  .footer-wrap {
    padding: 0 24px 20px 24px;
  }
}
</style>
